import { registerApplication } from 'single-spa';
import { activeTopbar, activeProjetosSidebar } from './utils/activeMF';

export default () => {
  registerApplication({
    name: 'mf-login',
    activeWhen: (location) => location.pathname.startsWith('/login') || location.pathname === '/',
    app: () => global.System.import('mf-login'),
  });

  registerApplication({
    name: 'mf-suite',
    activeWhen: (location) => location.pathname.startsWith('/suite'),
    app: () => global.System.import('mf-suite'),
  });

  registerApplication({
    name: 'mf-topbar',
    activeWhen: (location) => activeTopbar(location.pathname),
    app: () => global.System.import('mf-topbar'),
  });

  registerApplication({
    name: 'mf-rdo',
    activeWhen: (location) => location.pathname.startsWith('/rdo'),
    app: () => global.System.import('mf-rdo'),
  });

  registerApplication({
    name: 'mf-projetos',
    activeWhen: (location) => location.pathname.startsWith('/projetos'),
    app: () => global.System.import('mf-projetos'),
  });

  registerApplication({
    name: 'mf-sidebar',
    activeWhen: (location) => activeProjetosSidebar(location.pathname),
    app: () => global.System.import('mf-sidebar'),
  });

  registerApplication({
    name: 'mf-doc',
    activeWhen: (location) => location.pathname.startsWith('/doc'),
    app: () => global.System.import('mf-doc'),
  });

};
