import { start, addErrorHandler, getAppStatus } from 'single-spa';
import {
  constructRoutes,
  constructApplications,
  constructLayoutEngine,
} from 'single-spa-layout';

import moduleMaps from './importmap-modules';
import registerApps from './registerApps';

import './events';
import './bootstrap';

const init = () => {
  const routes = constructRoutes(document.querySelector('#single-spa-layout'), {
    loaders: {
      topBar: '<h1>Loading topnav</h1>',
      sideBar: '<h1>Loading sidebar</h1>',
    },
    errors: {
      topBar: '<h1>Failed to load topnav</h1>',
      sideBar: '<h1>Failed to load sideBar</h1>',
    },
  });

  const applications = constructApplications({
    routes,
    loadApp: ({ name }) => global.System.import(name),
  });

  const layoutEngine = constructLayoutEngine({
    routes,
    applications,
    active: false,
  });

  // Apps to be loaded/show on screen when the activeWhen is get a true return.
  global.System.import('mf-style-guide').then(() => {});
  registerApps();

  addErrorHandler((err) => {
    console.log(`${err.appOrParcelName} -> ${getAppStatus(err.appOrParcelName)}`);
    console.log(err);
  });

  layoutEngine.activate();
  start();
};

document.head.appendChild(
  Object.assign(document.createElement('script'), {
    type: 'systemjs-importmap',
    innerHTML: JSON.stringify(moduleMaps),
  }),
);

const overrideEl = document.getElementById('import-map-overrides');

if (overrideEl) document.head.appendChild(overrideEl);

init();
