import axios from 'axios';
import { getCookie, setCookie, deleteCookie } from '../utils/cookies';

export const LOCALSTORAGE_KEY = '@autodoc_auth';
export const REFRESH_TOKEN_NAME = `${LOCALSTORAGE_KEY}_refreshToken`;
export const APP_TOKEN_NAME = `${LOCALSTORAGE_KEY}_app`;
export const USER_DATA_NAME = `${LOCALSTORAGE_KEY}_user`;
export const AUTH_BASE_URL = 'https://sso.autodocdev.com/v1';
export const SUITE_BASE_URL = 'https://suite.autodocdev.com/v1';

export const getAppToken = () => getCookie(APP_TOKEN_NAME);

export const getRefreshToken = () => getCookie(REFRESH_TOKEN_NAME);

export const getUserByEmail = async (email) => {
  try {
    const { idToken } = window.autodoc.auth;
    const response = await axios.get(`${SUITE_BASE_URL}/users/email/${email}`, {
      headers: { Authorization: idToken },
    });

    return response.data.data;
  } catch (er) {
    return false;
  }
};

export const saveAuth = async (tokens) => {
  const {
    refreshToken,
    idToken,
    accessToken,
    expiresIn,
    tokenType,
    app,
  } = tokens;

  const cookieExpireDays = 1;
  setCookie(REFRESH_TOKEN_NAME, refreshToken, cookieExpireDays);
  setCookie(APP_TOKEN_NAME, app, cookieExpireDays);

  window.autodoc.auth = {
    idToken,
    accessToken,
    expiresIn,
    tokenType,
  };
};

export const logout = async () => {
  try {
    const app = getAppToken();
    const refreshToken = getRefreshToken();
    const { idToken } = window.autodoc.auth;

    await axios.post(`${AUTH_BASE_URL}/logout`, { refreshToken, app }, {
      headers: { Authorization: idToken },
    });

    deleteCookie(REFRESH_TOKEN_NAME);
    deleteCookie(APP_TOKEN_NAME);
    localStorage.removeItem(USER_DATA_NAME);

    window.autodoc.auth = {};
    window.autodoc.navigateToUrl('/login');

    return true;
  } catch (er) {
    return false;
  }
};

export const refreshIdToken = async () => {
  try {
    const { data } = await axios.post(`${AUTH_BASE_URL}/retrieve-new-token`);
    saveAuth({ ...data });
    return true;
  } catch (er) {
    console.log('erro', er);
    return false;
  }
};
