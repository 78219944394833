export function setCookie(name, value, expDays = 1) {
  const d = new Date();
  d.setTime(d.getTime() + (expDays * 24 * 60 * 60 * 1000));
  const expires = `expires=${d.toGMTString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
}

export function getCookie(name) {
  const cname = `${name}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(cname) === 0) {
      return c.substring(cname.length, c.length);
    }
  }
  return '';
}

export function deleteCookie(name) {
  const d = new Date();
  d.setTime(d.getTime() - (60 * 60 * 1000));
  const expires = `expires=${d.toGMTString()}`;
  document.cookie = `${name}=;${expires};path=/`;
}
