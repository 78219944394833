export const activeTopbar = (pathname) => [
  pathname.startsWith('/projetos'),
  pathname.startsWith('/doc'),
  pathname.startsWith('/rdo'),
  pathname.startsWith('/suite') && !pathname.includes('change-password'),
].some((condition) => condition);

export const activeProjetosSidebar = (pathname) => [
  pathname.startsWith('/projetos') && !pathname.includes('admin')
].some((condition) => condition);

