export default {
  imports: {
    'react-router-dom': 'https://unpkg.com/react-router-dom@6.3.0/umd/react-router-dom.production.min.js',
    'react-router': 'https://cdnjs.cloudflare.com/ajax/libs/react-router/6.3.0/react-router.production.min.js',
    react: 'https://cdn.jsdelivr.net/npm/react@18.2.0/umd/react.production.min.js',
    'react-dom': 'https://cdn.jsdelivr.net/npm/react-dom@18.2.0/umd/react-dom.production.min.js',
    'react-is': 'https://cdnjs.cloudflare.com/ajax/libs/react-is/18.2.0/umd/react-is.production.min.js',
    history: 'https://unpkg.com/history@5.3.0/umd/history.production.min.js',
    'styled-components': 'https://cdnjs.cloudflare.com/ajax/libs/styled-components/5.3.5/styled-components.min.js',
    antd: 'https://cdnjs.cloudflare.com/ajax/libs/antd/4.23.0/antd.min.js',
    moment: 'https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.29.4/moment.min.js',
    'single-spa': 'https://cdnjs.cloudflare.com/ajax/libs/single-spa/5.9.4/es2015/single-spa.dev.js',
    'single-spa-react': 'https://cdn.jsdelivr.net/npm/single-spa-react@5.0.0/lib/umd/single-spa-react.min.js',
  },
};
