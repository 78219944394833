import Eev from 'eev';
import * as eventTypes from './types';
import { saveAuth as saveTokens, logout as logoutF } from '../auth/auth';

export const eevInstance = new Eev();

// dispatcher
export const saveAuth = (tokens) => eevInstance.emit(eventTypes.SAVE_AUTH, tokens);

export const logout = () => eevInstance.emit(eventTypes.LOGOUT);

export const changeLanguage = (language) => eevInstance.emit(eventTypes.CHANGE_LANGUAGE, {
  language,
});

export { eventTypes };

// listener
eevInstance.on(eventTypes.SAVE_AUTH, (data) => {
  saveTokens(data);
});

eevInstance.on(eventTypes.LOGOUT, () => {
  logoutF();
});
